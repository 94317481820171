import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.to-string";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTokens } from '@/utils/auth';
import { getInfo } from '@/api/login';
import Head from '@/components/head/index';
import TimeScreeningse from '@/components/timeScreeningse';
//签约状态
// import Signingstatus from '@/components/Signingstatus';
//合作方
// import Partner from '@/components/Partner';
import checkBankMixin from '@/mixin/checkBank.js'; // 识别银行
import { GetAgentList, Agentatimg, Agentatfu, Agentatying, GetIdCode, addAgentList, delAgentList, Agenttop, AgenttransferCreateId, addAgentFollow, Agentatgenjinfu, AgentFollow, updateAgentList, Agentread, RepetitionAdd } from '@/api/ceshi_xiugai2/myproject';
import { agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import { getUserList } from '@/api/updateUserInfo/structure';
import area from '@/views/example/area';
import StaffSelect from '@/components/StaffSelect/seasbook';
import DisplaySettings from '@/components/DisplaySettings/index';
import axios from 'axios';
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
var fieldsdata = ['name', 'structure_id', 'post', 'remark', 'field', 'sex', 'birthday', 'phone', 'weixin', 'email', 'img', 'file', 'note', 'course', 'outline', 'bright_spot', 'schedule', 'attainment', 'pleased', 'home_area', 'food', 'stay', 'noon_break', 'bank', 'card_number', 'money', 'collect_type', 'collect_time', 'type', 'create_id', 'status', 'client_type', 'client_id'];
var defaultDisplayValue = ['序号', '置顶', '名称', '生日', '来源', '公司等级', '合作级别', '花名', '最后跟进日期', '联系人', '手机号', '公司规模', '性别', '创建时间', '跟进次数', '创建人'];
export default {
  name: 'Allitems',
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    StaffSelect: StaffSelect,
    DisplaySettings: DisplaySettings,
    ElImageViewer: ElImageViewer
  },
  mixins: [checkBankMixin],
  data: function data() {
    var _ref;
    return _ref = {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      menuId: localStorage.getItem('menuId'),
      // 系统教程
      // 显示图片查看器
      showImgViewer: false,
      // 查看大图列表
      imgPreviewList: [],
      //显示设置
      displayOptions: [],
      defaultDisplayValue: defaultDisplayValue,
      displayValue: [],
      disable: false,
      //禁用
      fieldsdata: fieldsdata,
      area: area,
      //地级市
      visible: false,
      title: '全部企业',
      value1: '',
      project_type: '',
      MainShow: false,
      ReceivertShow: false,
      Receiver: '',
      Receiver_Arr: [],
      isshowandhide: false,
      //公司或者个人的
      company_leibie: [],
      //类别
      leibie: '',
      //类别
      flower_Array: [],
      //花名
      cooperation_levels: [],
      //形式
      levels: '',
      //形式
      company_scale: [],
      //规模
      company_level: [],
      //等级
      company_group: [],
      //代理商分组。。状态
      partner_group: {
        1: '代理方',
        2: '项目方'
      },
      //合作方
      company_post: [],
      //岗位
      situation: [],
      tableData: [],
      Alltableoptions: [],
      dialogFormVisible: false,
      //新建
      dialogStatus: '',
      BusinessShow: false,
      BusinessArr: [{
        id: 1,
        label: '可认证'
      }, {
        id: 2,
        label: '不可认证'
      }, {
        id: 3,
        label: '社科高级'
      }],
      textMap: {
        update: '编辑企业',
        create: '新建企业'
      },
      rules: {
        c_type: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        project_type: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        identifier: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        flower_name: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '必填项',
          trigger: 'change'
        }],
        is_introduce: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        introducer_name: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        zhuti: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        a_group: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        full_name: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        subject: [{
          required: true,
          message: '主题不能为空',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: ['blur']
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入正确的手机号码',
          trigger: ['blur']
        }],
        email: [{
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: ['blur']
        }],
        // birthday: [{ required: true, message: '必填项', trigger: 'change' }],
        cooperation_level: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        leibie: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        area: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }]
      },
      temp: {
        // c_type: '', //项目方1代理商
        title: '',
        //企业名称
        flower_name: '',
        //花名
        shop_name: '',
        //店铺名称
        type: '',
        //类型0公司1个人
        is_introduce: 0,
        project_type: 1,
        introducer_name: '',
        a_group: '',
        //代理商分组1 => '已签约'
        scale: '',
        //公司规模
        post: '',
        //岗位1 => '职员', 2
        company_level: '',
        //公司等级1-5颗星
        company: '',
        //所属公司
        area: '',
        //省市区
        address: '',
        //经营地址
        web_site: '',
        //网址
        full_name: '',
        //联系人
        mobile: '',
        //手机号
        weixin: '',
        //微信
        qq: '',
        //qq
        email: '',
        //邮箱
        services: '',
        //负责业务
        birthday: '',
        //生日\
        sex: 0,
        //男0 女1
        img_id: '',
        //头像附件id
        file_id: '',
        //附件id
        note: '',
        //备注
        invoice_company: '',
        //开票公司
        identifier: '',
        //s	识别号
        bank: '',
        //开户行
        bank_name: '',
        //银行名称
        card_no: '',
        //卡号
        bank_address: '',
        //银行地址
        legal_person: '',
        //法人
        business_license_id: '',
        //营业执照附件id
        cooperation_level: '',
        //合作级别形式
        leibie: '',
        //类别
        landline_phone: '',
        //座机
        username: '',
        //用户名
        password: '' //密码
      },
      getinfo: {
        page: '',
        pagesize: '',
        start_time: '',
        end_time: '',
        cooperation_level: '',
        //合作级形式
        leibie: '',
        //类别
        c_type: '',
        //合作方
        a_group: '',
        //状态
        start_follow_time: '',
        //跟进时间（开始）
        end_follow_time: '',
        //跟进时间（结束）
        full_name: '',
        create_time_order: '',
        //创建日期排序
        follow_time_order: '',
        //最后跟进日期排序
        key_word: '',
        source_client_id: '',
        //花名id
        identifier: '',
        create_id: ''
      },
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      key_word: '',
      //手机号/微信号
      imageUrl: '',
      yingye: [],
      fujian: [],
      tempId: [],
      //选中用的 id
      //移交
      staffSelectVisible: false,
      staffResult: [],
      staffDisableArr: [],
      shareDialog: false,
      radio: false,
      followUpDialog: false,
      //新建跟进
      tempFollowup: {
        aid: '',
        subject: '',
        type: '1',
        next_time: '',
        file_id: '',
        name: ''
      },
      typeOptionsArr: [],
      //跟进类型
      follows_up: [],
      //跟进列表
      NumberEs: 2,
      contactsshow: false,
      createtimeshow: false,
      finalFollowshow: false,
      contact_name: '',
      //联系人
      finalFollow: '',
      partner: '',
      dialogZd: false,
      zdName: '',
      zdFlower: '',
      zdAddress: '',
      zdContact: '',
      zdMobile: '',
      zdCompany: '',
      zdId: '',
      zdUsername: '',
      formLabelWidth: '120px',
      repetition_id: '',
      a_group: ''
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "leibie", ''), "type", ''), "cooperation_level", ''), "send_group", ''), "send_leibie", ''), "send_title", ''), "send_type", ''), "send_cooperation_level", ''), "pickerOptions", {
      disabledDate: function disabledDate(v) {
        return v.getTime() < new Date().getTime() - 86400000;
      }
    }), "userId", ''), _defineProperty(_ref, "admin", '');
  },
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.flowerArray(); //花名
      getInfo().then(function (res) {
        _this.userId = res.data.id;
        _this.admin = res.data.is_admin;
      });
      this.GetList(this.query.page, this.query.pagesize);
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.tb.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
    } else {}
  },
  methods: {
    // 打开图片查看器
    openImgViewer: function openImgViewer(file) {
      // 获取要查看的图片临时地址
      this.imgPreviewList = [file.url];

      // 显示图片查看器
      this.showImgViewer = true;
    },
    // 关闭图片查看器
    closeImgViewer: function closeImgViewer() {
      this.showImgViewer = false;
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '联系人') {
        this.contactsshow = true;
      } else if (item === '创建时间') {
        this.createtimeshow = true;
      } else if (item == '最后跟进日期') {
        this.finalFollowshow = true;
      } else if (item == '创建人') {
        this.ReceivertShow = true;
        this.ReceiverBlur();
      } else if (item == '主营业务') {
        this.MainShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '联系人') {
        this.contactsshow = false;
        this.getinfo.full_name = '';
        this.contact_name = '';
        this.GetList(this.query.page, this.query.pagesize);
      } else if (item === '创建时间') {
        this.createtimeshow = false;
        this.getinfo.start_time = '';
        this.getinfo.end_time = '';
        this.value1 = '';
        this.GetList(this.query.page, this.query.pagesize);
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = false;
        this.getinfo.start_follow_time = '';
        this.getinfo.end_follow_time = '';
        this.finalFollow = '';
        this.GetList(this.query.page, this.query.pagesize);
      } else if (item === '创建人') {
        this.ReceivertShow = false;
        this.Receiver = '';
        this.GetList(this.query.page, this.query.pagesize);
      } else if (item == '主营业务') {
        this.MainShow = false;
        this.project_type = '';
        this.GetList(this.query.page, this.query.pagesize);
      }
    },
    //排序
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        //创建时间
        if (column.order == 'ascending') {
          this.getinfo.create_time_order = '1';
        } else if (column.order == 'descending') {
          this.getinfo.create_time_order = '2';
        } else {
          this.getinfo.create_time_order = '0';
        }
        this.getinfo.follow_time_order = '0';
      } else if (column.prop == 'follow_time') {
        //最后跟进日期
        if (column.order == 'ascending') {
          this.getinfo.follow_time_order = '1';
        } else if (column.order == 'descending') {
          this.getinfo.follow_time_order = '2';
        } else {
          this.getinfo.follow_time_order = '0';
        }
        this.getinfo.create_time_order = '0';
      }
      this.GetList(this.query.page, this.query.pagesize);
    },
    ProjectType: function ProjectType() {
      this.query.page = 1;
      this.GetList(this.query.page, this.query.pagesize);
    },
    //联系人
    contacts: function contacts() {
      this.getinfo.full_name = this.contact_name;
      this.GetList(this.query.page, this.query.pagesize);
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      var _this2 = this;
      //最后跟进日期筛选

      if (this.finalFollow == null) {
        this.getinfo.start_follow_time = '';
        this.getinfo.end_follow_time = '';
        this.$nextTick(function () {
          _this2.GetList(_this2.query.page, _this2.query.pagesize);
        });
      } else {
        var sf_time = this.finalFollow[0].toString();
        this.getinfo.start_follow_time = sf_time.substring(0, 10);
        var ef_time = this.finalFollow[1].toString();
        this.getinfo.end_follow_time = ef_time.substring(0, 10);
        this.$nextTick(function () {
          _this2.GetList(_this2.query.page, _this2.query.pagesize);
        });
      }
    },
    fujianRemove: function fujianRemove() {
      this.temp.file_id = '';
    },
    yingyeRemove: function yingyeRemove() {
      this.temp.business_license_id = '';
    },
    // 设置滚动条相关信息
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.tb.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    GetList: function GetList(page, pagesize) {
      var _this3 = this;
      this.getinfo.page = page;
      this.getinfo.pagesize = pagesize;
      this.getinfo.create_id = this.Receiver;
      this.getinfo.project_type = this.project_type;
      GetAgentList(this.getinfo).then(function (respomse) {
        _this3.tableData = respomse.data.data;
        _this3.query.total = respomse.data.total;
        //形式类别数据
        _this3.company_leibie = respomse.data.company_leibie;
        _this3.cooperation_levels = respomse.data.cooperation_levels;
        _this3.company_scale = respomse.data.company_scale; //公司规模
        _this3.company_post = respomse.data.company_post; //岗位
        _this3.company_level = respomse.data.company_level; //等级
        _this3.company_group = respomse.data.company_group; //签约类型
        _this3.displayValue = respomse.data.fields;
        _this3.displayOptions = [];
        // 表头
        for (var item in respomse.data.agent_fields) {
          _this3.displayOptions.push(respomse.data.agent_fields[item]);
        }
      });
    },
    //形式类别数据
    // getcompany() {
    //     GetAgentList().then(respomse => {
    //         this.company_leibie = respomse.data.company_leibie;
    //         this.cooperation_levels = respomse.data.cooperation_levels;
    //         this.company_scale = respomse.data.company_scale; //公司规模
    //         this.company_post = respomse.data.company_post; //岗位
    //         this.company_level = respomse.data.company_level; //等级
    //         this.company_group = respomse.data.company_group; //签约类型
    //         this.displayValue = respomse.data.fields
    //         for (const item in respomse.data.agent_fields) {
    //             this.displayOptions.push(respomse.data.agent_fields[item]);
    //         }
    //     });
    // },
    flowerArray: function flowerArray() {
      var _this4 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this4.flower_Array = res.data;
      });
    },
    flowerArrayEs: function flowerArrayEs(val) {},
    //时间
    screening: function screening(item) {
      var _this5 = this;
      this.value1 = '';
      this.getinfo.start_time = item[0];
      this.getinfo.end_time = item[1];
      this.$nextTick(function () {
        _this5.GetList(_this5.query.page, _this5.query.pagesize);
      });
    },
    //签约状态
    // status(item) {
    //     this.getinfo.a_group = item;
    //     this.$nextTick(() => {
    //         this.GetList();
    //     });
    // },
    handleStatus: function handleStatus(val) {
      this.getinfo.a_group = val;
      // this.getinfo.page = 1;
      // this.query.page = 1;
      // this.$nextTick(() => {
      //     this.GetList(this.query.page, this.query.pagesize);
      // });
    },
    //合作方
    // partner(item) {
    //     this.getinfo.c_type = item;
    //     this.$nextTick(() => {
    //         this.GetList();
    //     });
    // },
    handlePartner: function handlePartner(val) {
      var _this6 = this;
      this.getinfo.c_type = val;
      this.$nextTick(function () {
        _this6.GetList(1, _this6.query.pagesize);
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this7 = this;
      this.NumberEs = Number(0);
      if (this.value1 == null) {
        this.getinfo.start_time = '';
        this.getinfo.end_time = '';
        this.$nextTick(function () {
          _this7.GetList(_this7.query.page, _this7.query.pagesize);
        });
      } else {
        var s_time = this.value1[0].toString();
        this.getinfo.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.getinfo.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this7.GetList(_this7.query.page, _this7.query.pagesize);
        });
      }
    },
    //查询
    handleFilter: function handleFilter() {
      var _this8 = this;
      this.getinfo.key_word = this.key_word;
      this.query.page = 1;
      if (this.key_word !== '') {
        GetAgentList({
          page: this.getinfo.page,
          pagesize: this.getinfo.pagesize,
          key_word: this.key_word
        }).then(function (respomse) {
          _this8.tableData = respomse.data.data;
        });
      } else {
        this.$nextTick(function () {
          _this8.GetList(_this8.query.page, _this8.query.pagesize);
        });
      }
    },
    search: function search() {
      var _this9 = this;
      this.getinfo.page = 1;
      this.query.page = 1;
      this.$nextTick(function () {
        _this9.GetList(_this9.query.page, _this9.query.pagesize);
      });
    },
    GETIdentifier: function GETIdentifier(id) {
      var _this10 = this;
      GetIdCode({
        identifier: id,
        project_type: this.temp.project_type
      }).then(function (res) {
        _this10.repetition_id = res.msg.id;
        if (res.code != 0) {
          _this10.dialogZd = true;
          _this10.zdName = res.msg.title;
          _this10.zdFlower = res.msg.flower_name;
          _this10.zdAddress = res.msg.address;
          _this10.zdContact = res.msg.full_name;
          _this10.zdMobile = res.msg.mobile;
          _this10.zdCompany = res.msg.invoice_company;
          _this10.zdId = res.msg.identifier;
          _this10.zdUsername = res.msg.username;
          _this10.a_group = res.msg.a_group;
          _this10.leibie = res.msg.leibie;
          _this10.title = res.msg.title;
          _this10.type = res.msg.type;
          _this10.cooperation_level = res.msg.cooperation_level;
        }
      });
    },
    ReceiverBlur: function ReceiverBlur() {
      var _this11 = this;
      getUserList({
        get_type: '6',
        type: '0'
      }).then(function (res) {
        _this11.Receiver_Arr = res.data;
      });
    },
    ReceiverInput: function ReceiverInput() {
      this.query.page = 1;
      this.GetList(this.query.page, this.query.pagesize);
    },
    ReceiverMethod: function ReceiverMethod(val) {
      var _this12 = this;
      //数据接收人搜索
      getUserList({
        get_type: '6',
        type: '0',
        real_name: val
      }).then(function (res) {
        _this12.Receiver_Arr = res.data;
      });
    },
    //撞单保存
    repetitionAdd: function repetitionAdd() {
      var _this13 = this;
      if (this.temp.a_group == '') {
        this.send_group = this.a_group;
      } else {
        this.send_group = parseInt(this.temp.a_group);
      }
      if (this.temp.leibie == '') {
        this.send_leibie = this.leibie;
      } else {
        this.send_leibie = parseInt(this.temp.leibie);
      }
      if (this.temp.title == '') {
        this.send_title = this.title;
      } else {
        this.send_title = this.temp.title;
      }
      if (this.temp.type == '') {
        this.send_type = this.type;
      } else {
        this.send_type = parseInt(this.temp.type);
      }
      if (this.temp.cooperation_level == '') {
        this.send_cooperation_level = this.cooperation_level;
      } else {
        this.send_cooperation_level = parseInt(this.temp.cooperation_level);
      }
      RepetitionAdd({
        repetition_id: this.repetition_id,
        identifier: this.temp.identifier,
        title: this.send_title,
        type: this.send_type,
        a_group: this.send_group,
        leibie: this.send_leibie,
        cooperation_level: this.send_cooperation_level
      }).then(function (res) {
        if (res.code == 0) {
          _this13.$notify({
            message: '保存成功',
            type: 'success'
          });
          _this13.dialogZd = false;
          _this13.dialogFormVisible = false;
          _this13.$nextTick(function () {
            //获取列表方法
            _this13.GetList(_this13.query.page, _this13.query.pagesize);
          });

          // Object.keys(this.temp).forEach(key => (this.temp[key] = '')); //清空对象的属性值
          _this13.imageUrl = '';
        } else {
          _this13.$message.error(res.msg);
        }
      });
    },
    //省市区下拉
    changeVal: function changeVal() {
      this.$refs.cascader.dropDownVisible = false;
    },
    //形式
    levelsclick: function levelsclick() {
      // this.getinfo.page = 1;
      // this.query.page = 1;
      // this.$nextTick(() => {
      //     this.GetList(this.query.page, this.query.pagesize);
      // });
    },
    //类别
    leibieclick: function leibieclick() {
      // this.getinfo.page = 1;
      // this.query.page = 1;
      // this.$nextTick(() => {
      //     this.GetList(this.query.page, this.query.pagesize);
      // });
    },
    //单选
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      var ids = [];
      for (var i = 0; i < val.length; i++) {
        ids.push(val[i].id);
      }
      this.tempId = ids;
      //单选时打开此代码
      // if (val.length > 1) {
      //     this.$refs.tb.clearSelection();
      //     this.$refs.tb.toggleRowSelection(val.pop());
      // }
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this14 = this;
      this.getinfo.page = val;
      this.query.page = val;
      this.$nextTick(function () {
        //获取列表方法
        _this14.GetList(_this14.query.page, _this14.query.pagesize);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this15 = this;
      this.query.pagesize = val;
      this.getinfo.pagesize = val;
      this.$nextTick(function () {
        //获取列表方法
        _this15.GetList(_this15.query.page, _this15.query.pagesize);
      });
    },
    //营业执照附件id
    yingyeup: function yingyeup(param) {
      var _this16 = this;
      Agentatying(param).then(function (res) {
        _this16.temp.business_license_id = res.data.id;
      });
    },
    handleChange: function handleChange(file, fileList) {
      this.yingye = fileList.slice(-1);
    },
    //附件上传
    fujianup: function fujianup(param) {
      var _this17 = this;
      Agentatfu(param).then(function (res) {
        _this17.temp.file_id = res.data.id;
      }).catch();
    },
    //上传公司logo
    Imageup: function Imageup(param) {
      var _this18 = this;
      Agentatimg(param).then(function (res) {
        _this18.imageUrl = process.env.VUE_APP_BASE_API2 + res.data.path;
        _this18.temp.img_id = res.data.id;
      });
    },
    //公司logo
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      var isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 和 Png 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 3MB!');
        return false;
      }
    },
    introduceChange: function introduceChange(val) {
      if (val == 0) {
        this.temp.introducer_name = '';
      }
    },
    // 新建
    handleAddClass: function handleAddClass() {
      var _this19 = this;
      this.imageUrl = '';
      this.fujian = [];
      this.yingye = [];
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this19.$refs.dataForm.resetFields();
      });
      this.BusinessShow = false;
      // Object.keys(this.temp).forEach(key => (this.temp[key] = ''));
      // this.$refs.uploadLogo.clearFiles();
      // this.$refs['uploadFj'].clearFiles();
      // this.$refs['upload'].clearFiles();
    },
    createData: function createData() {
      var _this20 = this;
      this.temp.area = this.temp.area.toString();
      addAgentList(this.temp).then(function (res) {
        _this20.$notify({
          message: '添加成功',
          type: 'success'
        });
        _this20.dialogFormVisible = false;
        _this20.query.page = 1;
        _this20.$nextTick(function () {
          //获取列表方法
          _this20.GetList(_this20.query.page, _this20.query.pagesize);
        }).catch(function () {});

        // Object.keys(this.temp).forEach(key => (this.temp[key] = '')); //清空对象的属性值
      });
    },
    // 编辑
    handleUpdateBtn: function handleUpdateBtn() {
      var _this21 = this;
      if (this.Alltableoptions.length == 0) {
        this.$message('请选择要编辑的企业');
      } else if (this.Alltableoptions.length > 1) {
        this.$message('请选择一个进行编辑');
      } else {
        if (this.admin == 1 || this.Alltableoptions[0].create_id == this.userId) {
          this.dialogStatus = 'update';
          this.dialogFormVisible = true;
          this.disable = true;
          Agentread({
            id: this.tempId.toString()
          }).then(function (res) {
            _this21.temp = Object.assign({}, res.data);
            _this21.BusinessShow = true;
            // this.temp.leibie = String(this.temp.leibie);
            // this.temp.a_group = String(this.temp.a_group);
            _this21.temp.next_follow = String(_this21.temp.next_follow) + '000';
            _this21.temp.area = _this21.temp.area.split(',');
            _this21.fujian = [];
            _this21.yingye = [];
            if (res.data.file_info == '') {
              _this21.fujian = [];
            } else {
              _this21.fujian.push(res.data.file_info);
            }
            if (res.data.business_license_info == '') {
              _this21.yingye = [];
            } else {
              res.data.business_license_info.url = process.env.VUE_APP_BASE_API2 + res.data.business_license_info.url;
              _this21.yingye.push(res.data.business_license_info);
            }
            if (res.data.leibie == 0) {
              _this21.temp.leibie = '';
            } else {
              _this21.temp.leibie = String(res.data.leibie);
            }
            if (res.data.a_group == 0) {
              _this21.temp.a_group = '';
            } else {
              _this21.temp.a_group = String(res.data.a_group);
            }
            if (res.data.title == 0) {
              _this21.temp.title = '';
            } else {
              _this21.temp.title = String(res.data.title);
            }
            if (res.data.cooperation_level == 0) {
              _this21.temp.cooperation_level = '';
            } else {
              _this21.temp.cooperation_level = res.data.cooperation_level;
            }
            if (res.data.scale == 0) {
              _this21.temp.scale = '';
            } else {
              _this21.temp.scale = String(res.data.scale);
            }
            if (res.data.post == 0) {
              _this21.temp.post = '';
            } else {
              _this21.temp.post = String(res.data.post);
            }
            if (res.data.company_level == 0) {
              _this21.temp.company_level = '';
            }
            if (res.data.qq == 0) {
              _this21.temp.qq = '';
            }
            if (res.data.img_info.url == undefined) {
              _this21.imageUrl = ' https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/a55c0a2be09adc0df6c564a7c4fbac6b.png';
            } else {
              _this21.imageUrl = process.env.VUE_APP_BASE_API2 + res.data.img_info.url;
            }
          });
        } else {
          this.$message('非企业创建人不可编辑该企业信息，请联系创建人修改');
        }
      }
    },
    updateData: function updateData() {
      var _this22 = this;
      // this.temp.area = this.temp.area.join(',');
      this.temp.area = this.temp.area.toString();
      var next_follow = this.temp.next_follow.toString();
      this.temp.next_follow = next_follow.substring(0, 10);
      updateAgentList(this.temp).then(function (res) {
        _this22.$notify({
          message: '修改成功',
          type: 'success'
        });
        _this22.dialogFormVisible = false;
        _this22.query.page = 1;
        _this22.$nextTick(function () {
          //获取列表方法
          _this22.GetList(_this22.query.page, _this22.query.pagesize);
        });
      }).catch(function () {});
    },
    //删除
    handledelgen: function handledelgen() {
      var _this23 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('企业删除不可恢复，确定删除该企业？', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            ids: _this23.tempId.toString()
          };
          delAgentList(para).then(function (res) {
            _this23.$notify({
              message: '删除成功',
              type: 'success'
            });
            _this23.query.page = 1;
            _this23.$nextTick(function () {
              //获取列表方法
              _this23.GetList(_this23.query.page, _this23.query.pagesize);
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中删除项');
      }
    },
    //置顶
    topOfList: function topOfList(item, id) {
      var _this24 = this;
      //置顶
      Agenttop({
        is_top: item,
        id: String(id)
      }).then(function (res) {
        _this24.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });
        _this24.GetList(_this24.query.page, _this24.query.pagesize);
      });
    },
    //移交
    handleAgentChange: function handleAgentChange() {
      if (this.Alltableoptions.length !== 0) {
        this.staffSelectVisible = true;
        this.radio = true;
      } else {
        this.$message('请选择移交项');
      }
    },
    seasbookstaff: function seasbookstaff(resultNameArr, result) {
      var _this25 = this;
      var new_uid = result.toString();
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确认移交该记录吗?', '提示', {
          type: 'warning'
        }).then(function () {
          // let ids = this.Alltableoptions.map(item => item.id).join();
          AgenttransferCreateId({
            ids: _this25.tempId.toString(),
            new_uid: new_uid
          }).then(function (res) {
            _this25.$notify({
              message: '移交成功',
              type: 'success'
            });
            _this25.GetList(_this25.query.page, _this25.query.pagesize);

            // this.$nextTick(() => {
            //     //获取列表方法
            //     this.GetList();
            // });
          });
        });
      } else {
        this.$message('请选中移交项');
      }
    },
    //跳详情
    goTodetails: function goTodetails(row) {
      var routeUrl = this.$router.resolve({
        path: '/Allitemsdetail',
        query: {
          id: row.id,
          title: row.title
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    //新建跟进附件
    FollowupBtn: function FollowupBtn(data) {
      var _this26 = this;
      this.followUpDialog = true;
      this.tempFollowup.name = data.title;
      this.tempFollowup.aid = data.id;
      this.tempFollowup.subject = '';
      this.tempFollowup.next_time = '';
      // this.Unfollowshow = false;
      AgentFollow({
        aid: data.id
      }).then(function (res) {
        _this26.typeOptionsArr = res.data.agent_follow_type;
        _this26.follows_up = res.data.follow_list;
      });
    },
    //新建附件
    addFollowUp: function addFollowUp() {
      var _this27 = this;
      if (this.tempFollowup.type == '') {
        this.$message({
          type: 'warning',
          message: '请选择跟进类型'
        });
      } else if (this.tempFollowup.subject == '') {
        this.$message({
          type: 'warning',
          message: '请输入主题'
        });
      } else {
        addAgentFollow({
          aid: this.tempFollowup.aid,
          subject: this.tempFollowup.subject,
          type: this.tempFollowup.type,
          next_time: this.tempFollowup.next_time.toString().substr(0, 10),
          file_id: this.tempFollowup.file_id,
          name: this.tempFollowup.name
        }).then(function (res) {
          _this27.$notify({
            message: '提交成功',
            type: 'success'
          });
          _this27.followUpDialog = false;
          _this27.tempFollowup.next_time = '';
          _this27.tempFollowup.subject = '';
          _this27.$nextTick(function () {
            _this27.GetList(_this27.query.page, _this27.query.pagesize);
          });
        });
      }
    },
    //跟进附件接口
    uploadSectionFile: function uploadSectionFile(param) {
      var _this28 = this;
      Agentatgenjinfu(param).then(function (res) {
        _this28.tempFollowup.file_id = res.data.id;
      });
    },
    //下载方法
    downloadExcel: function downloadExcel(res) {
      var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名.xlsx';
      var a = document.createElement('a');
      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      a.click();
    },
    //导出
    agentatExcel: function agentatExcel() {
      var _this29 = this;
      if (this.Alltableoptions.length !== 0) {
        // let ids = this.Alltableoptions.map(item => item.id).join();
        axios.defaults.headers.common['token'] = getTokens();
        axios.defaults.headers.common['content-type'] = 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        axios.post('https://gewawe.yangongzi.cn/clientserver.php/Agent/exportExcel', {
          ids: this.tempId.toString(),
          fields: this.fieldsdata.toString()
        }, {
          responseType: 'blob'
        }).then(function (res) {
          _this29.downloadExcel(res, '会员基础信息.xlsx');
        });
      } else {
        this.$message('请选择导出列表');
      }
    }
  },
  watch: {
    //此步骤是为了更清楚的格式化银行卡号，每4位一个空格
    'temp.card_no': function tempCard_no(val) {
      var _this30 = this;
      this.$nextTick(function () {
        _this30.temp.card_no = val.replace(/\D/g, '').replace(/....(?!$)/g, '$& ');
      });
    }
  }
};