var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container Allitems" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { padding: "10px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { padding: "10px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-bottom": "20px" },
                        attrs: { xs: 24, sm: 11, md: 9, lg: 6, xl: 4 },
                      },
                      [
                        _vm._v(
                          "\n                    签约状态:\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.getinfo.a_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.getinfo, "a_group", $$v)
                              },
                              expression: "getinfo.a_group",
                            },
                          },
                          _vm._l(_vm.company_group, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-bottom": "20px" },
                        attrs: { xs: 24, sm: 11, md: 9, lg: 6, xl: 4 },
                      },
                      [
                        _vm._v(
                          "\n                    合作级别:\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            staticStyle: { width: "140px" },
                            attrs: {
                              size: "small",
                              clearable: "",
                              placeholder: "请选择级别",
                            },
                            model: {
                              value: _vm.getinfo.cooperation_level,
                              callback: function ($$v) {
                                _vm.$set(_vm.getinfo, "cooperation_level", $$v)
                              },
                              expression: "getinfo.cooperation_level",
                            },
                          },
                          _vm._l(
                            _vm.cooperation_levels,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: index },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-bottom": "20px" },
                        attrs: { xs: 24, sm: 11, md: 9, lg: 6, xl: 4 },
                      },
                      [
                        _vm._v(
                          "\n                    项目类别:\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "filter-item",
                            staticStyle: { width: "140px" },
                            attrs: {
                              size: "small",
                              clearable: "",
                              placeholder: "请选择类别",
                            },
                            model: {
                              value: _vm.getinfo.leibie,
                              callback: function ($$v) {
                                _vm.$set(_vm.getinfo, "leibie", $$v)
                              },
                              expression: "getinfo.leibie",
                            },
                          },
                          _vm._l(_vm.company_leibie, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-bottom": "20px" },
                        attrs: { xs: 24, sm: 11, md: 9, lg: 6, xl: 4 },
                      },
                      [
                        _vm._v(
                          "\n                    花名:\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            attrs: {
                              size: "small",
                              filterable: "",
                              placeholder: "请搜索花名",
                              clearable: "",
                            },
                            model: {
                              value: _vm.getinfo.source_client_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.getinfo, "source_client_id", $$v)
                              },
                              expression: "getinfo.source_client_id",
                            },
                          },
                          _vm._l(_vm.flower_Array, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.flower_name,
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-bottom": "20px" },
                        attrs: { xs: 15, sm: 10, md: 7, lg: 5, xl: 3 },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "手机号、名称查询客户",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.getinfo.key_word,
                            callback: function ($$v) {
                              _vm.$set(_vm.getinfo, "key_word", $$v)
                            },
                            expression: "getinfo.key_word",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "padding-bottom": "20px" },
                        attrs: { xs: 15, sm: 10, md: 7, lg: 5, xl: 4 },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "纳税人识别号/身份证号码查询客户",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.getinfo.identifier,
                            callback: function ($$v) {
                              _vm.$set(_vm.getinfo, "identifier", $$v)
                            },
                            expression: "getinfo.identifier",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.search },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6, lg: 8, xl: 5 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建"],
                                expression: "['新建']",
                              },
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleAddClass },
                          },
                          [_vm._v("新增")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["编辑"],
                                expression: "['编辑']",
                              },
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleUpdateBtn },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["删除"],
                                expression: "['删除']",
                              },
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handledelgen },
                          },
                          [_vm._v("删除")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["移交"],
                                expression: "['移交']",
                              },
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleAgentChange },
                          },
                          [_vm._v("移交")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { margin: "0px 0px" },
                        attrs: { span: 3 },
                      },
                      [
                        _c("display-settings", {
                          staticClass: "filter-item",
                          attrs: {
                            "display-arr": _vm.displayOptions,
                            "display-value": _vm.displayValue,
                            "default-value": _vm.defaultDisplayValue,
                            cname: _vm.title,
                          },
                          on: {
                            "update:displayValue": function ($event) {
                              _vm.displayValue = $event
                            },
                            "update:display-value": function ($event) {
                              _vm.displayValue = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.contactsshow,
                              expression: "contactsshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("联系人:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                placeholder: "请搜索联系人",
                                size: "small",
                              },
                              on: { change: _vm.contacts },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.contacts($event)
                                },
                              },
                              model: {
                                value: _vm.contact_name,
                                callback: function ($$v) {
                                  _vm.contact_name = $$v
                                },
                                expression: "contact_name",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.contacts },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("联系人")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ReceivertShow,
                                expression: "ReceivertShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("创建人：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                ref: "clearValue",
                                staticStyle: { "margin-left": "20px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  clearable: "",
                                  size: "small",
                                  "collapse-tags": "",
                                  "reserve-keyword": "",
                                  "remote-method": _vm.ReceiverMethod,
                                  placeholder: "请选择创建人",
                                },
                                on: { change: _vm.ReceiverInput },
                                model: {
                                  value: _vm.Receiver,
                                  callback: function ($$v) {
                                    _vm.Receiver = $$v
                                  },
                                  expression: "Receiver",
                                },
                              },
                              _vm._l(_vm.Receiver_Arr, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.realname,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.MainShow,
                              expression: "MainShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("主营业务:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              attrs: { size: "small" },
                              on: { change: _vm.ProjectType },
                              model: {
                                value: _vm.project_type,
                                callback: function ($$v) {
                                  _vm.project_type = $$v
                                },
                                expression: "project_type",
                              },
                            },
                            _vm._l(_vm.BusinessArr, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("主营业务")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.createtimeshow,
                              expression: "createtimeshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("创建时间:"),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "  结束日期",
                              "value-format": "timestamp",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            on: { change: _vm.DataTime },
                            model: {
                              value: _vm.value1,
                              callback: function ($$v) {
                                _vm.value1 = $$v
                              },
                              expression: "value1",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("创建时间")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.finalFollowshow,
                              expression: "finalFollowshow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("最后跟进日期:"),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "234px !important" },
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "  结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "timestamp",
                            },
                            on: { change: _vm.FinalFollowUpEs },
                            model: {
                              value: _vm.finalFollow,
                              callback: function ($$v) {
                                _vm.finalFollow = $$v
                              },
                              expression: "finalFollow",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("最后跟进日期")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "tooltip-effect": "dark",
                        id: "customerTable",
                        border: "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                          textAlign: "center",
                        },
                        "cell-style": { textAlign: "center" },
                      },
                      on: {
                        "sort-change": _vm.sortEs,
                        "selection-change": _vm.selsChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "50" },
                      }),
                      _vm._v(" "),
                      _vm.displayValue.includes("序号")
                        ? _c("el-table-column", {
                            key: 1,
                            attrs: { label: "序号", width: "50" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.query.page - 1) *
                                                _vm.query.pagesize +
                                              1
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3093164208
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("置顶")
                        ? _c("el-table-column", {
                            key: 2,
                            attrs: { label: "置顶", width: "100" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.is_top == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "1",
                                                    scope.row.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("置顶")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "warning",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "0",
                                                    scope.row.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消置顶")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              234164603
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("名称")
                        ? _c("el-table-column", {
                            key: 3,
                            attrs: { label: "名称", width: "200" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890FF" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goTodetails(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(scope.row.title)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3495067185
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("类型")
                        ? _c("el-table-column", {
                            key: 4,
                            attrs: { label: "类型", prop: "type" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("联系人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 5,
                              attrs: { label: "联系人", prop: "full_name" },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("联系人")
                                      },
                                    },
                                  },
                                  [_vm._v("联系人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("花名")
                        ? _c("el-table-column", {
                            key: 6,
                            attrs: { label: "花名", prop: "flower_name" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("手机号")
                        ? _c("el-table-column", {
                            key: 7,
                            attrs: { label: "手机号", prop: "mobile" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("签约状态")
                        ? _c("el-table-column", {
                            key: 8,
                            attrs: { label: "签约状态", prop: "a_group" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("合作级别")
                        ? _c("el-table-column", {
                            key: 9,
                            attrs: {
                              label: "合作级别",
                              prop: "cooperation_level",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("QQ")
                        ? _c("el-table-column", {
                            key: 11,
                            attrs: { label: "QQ", prop: "qq" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("类别")
                        ? _c("el-table-column", {
                            key: 12,
                            attrs: { label: "类别", prop: "leibie" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("微信")
                        ? _c("el-table-column", {
                            key: 13,
                            attrs: { label: "微信", prop: "weixin" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("性别")
                        ? _c("el-table-column", {
                            key: 14,
                            attrs: { label: "性别", prop: "sex" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("下次提醒日期")
                        ? _c("el-table-column", {
                            key: 15,
                            attrs: {
                              label: "下次提醒日期",
                              prop: "next_follow",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("最后跟进日期")
                        ? _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "最后跟进日期",
                                prop: "follow_time",
                                width: "150",
                                filters: [],
                                sortable: "custom",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("最后跟进日期")
                                      },
                                    },
                                  },
                                  [_vm._v("最后跟进日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("经营地址")
                        ? _c("el-table-column", {
                            key: 16,
                            attrs: { label: "经营地址", prop: "address" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("生日")
                        ? _c("el-table-column", {
                            key: 17,
                            attrs: { label: "生日", prop: "birthday" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("邮箱")
                        ? _c("el-table-column", {
                            key: 18,
                            attrs: { label: "邮箱", prop: "cemail" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("所属公司")
                        ? _c("el-table-column", {
                            key: 19,
                            attrs: { label: "所属公司", prop: "company" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("公司等级")
                        ? _c("el-table-column", {
                            key: 20,
                            attrs: { label: "公司等级", prop: "company_level" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(scope.row.company_level) +
                                          "星\n                        "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3935812748
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("备注")
                        ? _c("el-table-column", {
                            key: 21,
                            attrs: { label: "备注", prop: "note" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("岗位")
                        ? _c("el-table-column", {
                            key: 22,
                            attrs: { label: "岗位", prop: "post" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("公司规模")
                        ? _c("el-table-column", {
                            key: 23,
                            attrs: { label: "公司规模", prop: "scale" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("主营业务")
                        ? _c(
                            "el-table-column",
                            {
                              key: 36,
                              attrs: {
                                label: "主营业务",
                                width: "120",
                                prop: "project_type",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.project_type == 1
                                          ? _c("span", [_vm._v("可认证")])
                                          : scope.row.project_type == 2
                                          ? _c("span", [_vm._v("不可认证")])
                                          : _c("span", [_vm._v("社科高级")]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2307013525
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("主营业务")
                                      },
                                    },
                                  },
                                  [_vm._v("主营业务")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("创建人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 35,
                              attrs: {
                                label: "创建人",
                                width: "120",
                                prop: "create_name",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("创建人")
                                      },
                                    },
                                  },
                                  [_vm._v("创建人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("负责业务")
                        ? _c("el-table-column", {
                            key: 24,
                            attrs: { label: "负责业务", prop: "services" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("网址")
                        ? _c("el-table-column", {
                            key: 25,
                            attrs: { label: "网址", prop: "web_site" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("跟进次数")
                        ? _c("el-table-column", {
                            key: 26,
                            attrs: { label: "跟进次数", width: "100" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.FollowupBtn(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "跟进(" +
                                              _vm._s(scope.row.follow_up) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3350360351
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("创建时间")
                        ? _c(
                            "el-table-column",
                            {
                              key: 27,
                              attrs: {
                                label: "创建时间",
                                sortable: "custom",
                                width: "120",
                                prop: "create_time",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890FF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("创建时间")
                                      },
                                    },
                                  },
                                  [_vm._v("创建时间")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("座机")
                        ? _c("el-table-column", {
                            key: 28,
                            attrs: { label: "座机", prop: "landline_phone" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("来源")
                        ? _c("el-table-column", {
                            key: 29,
                            attrs: { label: "来源" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayValue.includes("介绍人")
                        ? _c("el-table-column", {
                            key: 30,
                            attrs: { label: "介绍人", prop: "introducer_name" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "tableLeft",
                      attrs: { id: "tableLeft" },
                      on: {
                        scroll: function ($event) {
                          return _vm.exterHandleScroll()
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "10px" },
                        attrs: { id: "bodyLeft" },
                      }),
                    ]
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: +_vm.temp.type ? "个人信息" : "企业信息",
                  visible: _vm.dialogZd,
                  width: "30%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogZd = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: +_vm.temp.type ? "个人名称:" : "公司名称:",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.zdName) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "花名:",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.zdFlower) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: +_vm.temp.type ? "地址:" : "公司地址:",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.zdAddress) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "联系人:",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.zdContact) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "电话号码:",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.zdMobile) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.temp.type == 0
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "开票公司名称:",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.zdCompany) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: +_vm.temp.type
                            ? "身份证号码:"
                            : "纳税人识别号:",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.zdId) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "用户名:",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.zdUsername) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-form-item", {
                      staticClass: "tips-info",
                      attrs: {
                        label: +_vm.temp.type
                          ? "您填写的企业有可复用信息，是否复用直接保存个人信息?"
                          : "您填写的企业有可复用信息，是否复用直接保存企业信息?",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogZd = false
                          },
                        },
                      },
                      [_vm._v("否，重新创建")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.repetitionAdd },
                      },
                      [_vm._v("是，直接保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialog",
                attrs: {
                  title: _vm.textMap[_vm.dialogStatus],
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    staticStyle: { "margin-left": "50px" },
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.temp,
                      "label-position": "right",
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c("div", { staticClass: "f18 b mb30 g3" }, [
                          _vm._v("基本信息"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "类型:", prop: "type" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: 0,
                                  disabled: _vm.isshowandhide,
                                },
                                model: {
                                  value: _vm.temp.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "type", $$v)
                                  },
                                  expression: "temp.type",
                                },
                              },
                              [_vm._v("公司")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: 1,
                                  disabled: _vm.isshowandhide,
                                },
                                model: {
                                  value: _vm.temp.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "type", $$v)
                                  },
                                  expression: "temp.type",
                                },
                              },
                              [_vm._v("个人")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否为转介绍:",
                              prop: "is_introduce",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.introduceChange },
                                model: {
                                  value: _vm.temp.is_introduce,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "is_introduce", $$v)
                                  },
                                  expression: "temp.is_introduce",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                                _vm._v(" "),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "主营业务:", prop: "project_type" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: {
                                  size: "small",
                                  disabled: _vm.BusinessShow,
                                },
                                model: {
                                  value: _vm.temp.project_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "project_type", $$v)
                                  },
                                  expression: "temp.project_type",
                                },
                              },
                              _vm._l(_vm.BusinessArr, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.temp.is_introduce == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "填写介绍人:",
                                  prop: "introducer_name",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    size: "small",
                                    placeholder: "请输入介绍人姓名",
                                    maxlength: "10",
                                  },
                                  model: {
                                    value: _vm.temp.introducer_name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.temp, "introducer_name", $$v)
                                    },
                                    expression: "temp.introducer_name",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "项目类别:", prop: "leibie" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: {
                                  size: "small",
                                  clearable: "",
                                  placeholder: "请选择类别",
                                },
                                model: {
                                  value: _vm.temp.leibie,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "leibie", $$v)
                                  },
                                  expression: "temp.leibie",
                                },
                              },
                              _vm._l(
                                _vm.company_leibie,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item, value: index },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "签约类型:", prop: "a_group" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: {
                                  size: "small",
                                  clearable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.temp.a_group,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "a_group", $$v)
                                  },
                                  expression: "temp.a_group",
                                },
                              },
                              _vm._l(_vm.company_group, function (item, index) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: index },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "合作级别:",
                              prop: "cooperation_level",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.temp.cooperation_level,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "cooperation_level", $$v)
                                  },
                                  expression: "temp.cooperation_level",
                                },
                              },
                              [_vm._v("普通合作")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 2 },
                                model: {
                                  value: _vm.temp.cooperation_level,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "cooperation_level", $$v)
                                  },
                                  expression: "temp.cooperation_level",
                                },
                              },
                              [_vm._v("战略合作")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: +_vm.temp.type ? "个人名称:" : "公司名称:",
                              prop: "title",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder:
                                  "请输入公司/个人全称,请认真填写,提交后不可更改!",
                              },
                              model: {
                                value: _vm.temp.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "title", $$v)
                                },
                                expression: "temp.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        this.dialogStatus === "create"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: +_vm.temp.type
                                    ? "身份证号码:"
                                    : "纳税人识别号:",
                                  prop: "identifier",
                                },
                              },
                              [
                                _vm.temp.type == 1
                                  ? _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        disabled: _vm.isshowandhide,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.GETIdentifier(
                                            _vm.temp.identifier
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.temp.identifier,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "identifier", $$v)
                                        },
                                        expression: "temp.identifier",
                                      },
                                    })
                                  : _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        disabled: _vm.isshowandhide,
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.GETIdentifier(
                                            _vm.temp.identifier
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.temp.identifier,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "identifier", $$v)
                                        },
                                        expression: "temp.identifier",
                                      },
                                    }),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: +_vm.temp.type
                                    ? "身份证号码:"
                                    : "纳税人识别号:",
                                  prop: "identifier",
                                },
                              },
                              [
                                _vm.temp.type == 1
                                  ? _c("el-input", {
                                      attrs: { size: "small", disabled: true },
                                      model: {
                                        value: _vm.temp.identifier,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "identifier", $$v)
                                        },
                                        expression: "temp.identifier",
                                      },
                                    })
                                  : _c("el-input", {
                                      attrs: { size: "small", disabled: true },
                                      model: {
                                        value: _vm.temp.identifier,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "identifier", $$v)
                                        },
                                        expression: "temp.identifier",
                                      },
                                    }),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: +_vm.temp.type ? "花名:" : "花名:",
                              prop: "flower_name",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder:
                                  "请输入公司/个人简称,请认真填写,提交后不可更改!",
                              },
                              model: {
                                value: _vm.temp.flower_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "flower_name", $$v)
                                },
                                expression: "temp.flower_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: +_vm.temp.type
                                ? "商城店铺名称:"
                                : "商城店铺名称:",
                              prop: "shop_name",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "请输入商城店铺名称",
                              },
                              model: {
                                value: _vm.temp.shop_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "shop_name", $$v)
                                },
                                expression: "temp.shop_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.temp.type == "1"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属公司:",
                                  prop: "company",
                                  disabled: _vm.isshowandhide,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "small",
                                    placeholder: "请输入详细地址",
                                  },
                                  model: {
                                    value: _vm.temp.company,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.temp, "company", $$v)
                                    },
                                    expression: "temp.company",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "省市区:", prop: "area" },
                                  },
                                  [
                                    _c("el-cascader", {
                                      ref: "cascader",
                                      staticClass: "pct50",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        value: _vm.temp.area,
                                        placeholder: "试试搜索：中国",
                                        options: _vm.area,
                                        size: "small",
                                        "expand-trigger": "hover",
                                        props: {
                                          checkStrictly: true,
                                          value: "label",
                                        },
                                        filterable: "",
                                      },
                                      on: { change: _vm.changeVal },
                                      model: {
                                        value: _vm.temp.area,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "area", $$v)
                                        },
                                        expression: "temp.area",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "详细地址:",
                                      prop: "address",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入详细地址",
                                      },
                                      model: {
                                        value: _vm.temp.address,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "address", $$v)
                                        },
                                        expression: "temp.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "公司网址:", prop: "web_site" } },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "请输入公司网址",
                              },
                              model: {
                                value: _vm.temp.web_site,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "web_site", $$v)
                                },
                                expression: "temp.web_site",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "公司规模:",
                                      prop: "scale",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item",
                                        attrs: {
                                          size: "small",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.temp.scale,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.temp, "scale", $$v)
                                          },
                                          expression: "temp.scale",
                                        },
                                      },
                                      _vm._l(
                                        _vm.company_scale,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item,
                                              value: index,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "公司等级:",
                                      prop: "company_level",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item",
                                        attrs: {
                                          size: "small",
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.temp.company_level,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.temp,
                                              "company_level",
                                              $$v
                                            )
                                          },
                                          expression: "temp.company_level",
                                        },
                                      },
                                      _vm._l(
                                        _vm.company_level,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: { label: item, value: item },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "公司logo:", prop: "img" } },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "uploadLogo",
                                staticClass: "avatar-uploader dian",
                                attrs: {
                                  "http-request": _vm.Imageup,
                                  action: "https://gewawe.yangongzi.cn/",
                                  "show-file-list": false,
                                  "before-upload": _vm.beforeAvatarUpload,
                                },
                              },
                              [
                                _vm.imageUrl
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: { src: _vm.imageUrl, alt: "" },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注:", prop: "remark" } },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                maxlength: "200",
                                type: "textarea",
                              },
                              model: {
                                value: _vm.temp.note,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "note", $$v)
                                },
                                expression: "temp.note",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              "margin-bottom": "30px",
                              width: "500px",
                              border: "none",
                            },
                            attrs: { label: "附件:", prop: "image_uri" },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "uploadFj",
                                staticClass: "avatar-uploader",
                                attrs: {
                                  "http-request": _vm.fujianup,
                                  "show-file-list": true,
                                  "file-list": _vm.fujian,
                                  action: "action",
                                  "before-remove": _vm.fujianRemove,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-row",
                          [
                            _c("div", { staticClass: "f18 b mb30 g3" }, [
                              _vm._v("联系人信息"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "联系人:",
                                      prop: "full_name",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入联系人",
                                      },
                                      model: {
                                        value: _vm.temp.full_name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "full_name", $$v)
                                        },
                                        expression: "temp.full_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "电话号码:",
                                      prop: "mobile",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入电话号码",
                                      },
                                      model: {
                                        value: _vm.temp.mobile,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "mobile", $$v)
                                        },
                                        expression: "temp.mobile",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "岗位:", prop: "post" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                attrs: {
                                  size: "small",
                                  clearable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.temp.post,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "post", $$v)
                                  },
                                  expression: "temp.post",
                                },
                              },
                              _vm._l(_vm.company_post, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: index },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "微信:", prop: "weixin" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入微信",
                                      },
                                      model: {
                                        value: _vm.temp.weixin,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "weixin", $$v)
                                        },
                                        expression: "temp.weixin",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "QQ:", prop: "qq" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder: "请输入QQ账号",
                                      },
                                      model: {
                                        value: _vm.temp.qq,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "qq", $$v)
                                        },
                                        expression: "temp.qq",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "邮箱:",
                              prop: "email",
                              placeholder: "请输入邮箱",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.temp.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "email", $$v)
                                },
                                expression: "temp.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "负责业务:", prop: "service_data" },
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.temp.services,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "services", $$v)
                                },
                                expression: "temp.services",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "性别:", prop: "sex" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 0 },
                                model: {
                                  value: _vm.temp.sex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "sex", $$v)
                                  },
                                  expression: "temp.sex",
                                },
                              },
                              [_vm._v("男")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: 1 },
                                model: {
                                  value: _vm.temp.sex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.temp, "sex", $$v)
                                  },
                                  expression: "temp.sex",
                                },
                              },
                              [_vm._v("女")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "出生日期:", prop: "birthday" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                size: "small",
                                type: "date",
                                placeholder: "选择日期 或输入yyyy-MM-dd",
                                format: "yyyy 年 MM 月 dd 日",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.temp.birthday,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "birthday", $$v)
                                },
                                expression: "temp.birthday",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c("div", { staticClass: "f18 b mb30 g3" }, [
                          _vm._v("开票信息"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "开票公司名称:",
                              prop: "invoice_company",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.temp.invoice_company,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "invoice_company", $$v)
                                },
                                expression: "temp.invoice_company",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "银行账号:", prop: "card_no" } },
                          [
                            _c("el-input", {
                              staticClass: "bankCard",
                              attrs: {
                                size: "small",
                                type: "text",
                                placeholder: "请输入银行卡账号",
                                maxlength: 24,
                              },
                              model: {
                                value: _vm.temp.card_no,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "card_no", $$v)
                                },
                                expression: "temp.card_no",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "银行名称:", prop: "bank" } },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "请输入银行名称",
                              },
                              model: {
                                value: _vm.temp.bank,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "bank", $$v)
                                },
                                expression: "temp.bank",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "开户行名称:",
                              prop: "bank_address",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.temp.bank_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "bank_name", $$v)
                                },
                                expression: "temp.bank_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "开户行地址:",
                              prop: "bank_address",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.temp.bank_address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "bank_address", $$v)
                                },
                                expression: "temp.bank_address",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "法人:", prop: "legal_person" } },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.temp.legal_person,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "legal_person", $$v)
                                },
                                expression: "temp.legal_person",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "座机:", prop: "landline_phone" } },
                          [
                            _c("el-input", {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.temp.landline_phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, "landline_phone", $$v)
                                },
                                expression: "temp.landline_phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              "margin-bottom": "30px",
                              width: "500px",
                            },
                            attrs: {
                              label: "营业执照附件:",
                              prop: "business_file",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                staticClass: "avatar-uploader yingye",
                                attrs: {
                                  "http-request": _vm.yingyeup,
                                  "on-change": _vm.handleChange,
                                  "show-file-list": true,
                                  "file-list": _vm.yingye,
                                  action: "action",
                                  "list-type": "picture-card",
                                  "before-remove": _vm.yingyeRemove,
                                  "on-preview": _vm.openImgViewer,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.showImgViewer
                              ? _c("el-image-viewer", {
                                  attrs: {
                                    "on-close": _vm.closeImgViewer,
                                    "url-list": _vm.imgPreviewList,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("div", { staticClass: "f18 b mb30 g3" }, [
                              _vm._v("分配账号"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "用户名:", prop: "username" } },
                              [
                                this.dialogStatus === "create"
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "text ",
                                        placeholder: "用户名",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.temp.username,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "username", $$v)
                                        },
                                        expression: "temp.username",
                                      },
                                    })
                                  : _c("el-input", {
                                      attrs: {
                                        type: "text ",
                                        placeholder: "用户名",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.temp.username,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "username", $$v)
                                        },
                                        expression: "temp.username",
                                      },
                                    }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            this.dialogStatus === "create"
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "密码:", prop: "password" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "可以不填写，默认密码为 admin123",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.temp.password,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.temp, "password", $$v)
                                        },
                                        expression: "temp.password",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogStatus === "create"
                              ? _vm.createData()
                              : _vm.updateData()
                          },
                        },
                      },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.followUpDialog
              ? _c(
                  "el-dialog",
                  {
                    attrs: { title: "跟进", visible: _vm.followUpDialog },
                    on: {
                      "update:visible": function ($event) {
                        _vm.followUpDialog = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c("el-col", { attrs: { md: 24, lg: 12, span: 12 } }, [
                          _c(
                            "div",
                            { staticClass: "grid-content bg-purple" },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "followUpForm",
                                  staticStyle: { "margin-left": "50px" },
                                  attrs: {
                                    "label-position": "right",
                                    "label-width": "120px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "姓名:" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入内容",
                                          disabled: true,
                                        },
                                        model: {
                                          value: _vm.tempFollowup.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tempFollowup,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "tempFollowup.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "is-required",
                                      attrs: {
                                        label: "主题:",
                                        prop: "subject",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "textarea", rows: 3 },
                                        model: {
                                          value: _vm.tempFollowup.subject,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tempFollowup,
                                              "subject",
                                              $$v
                                            )
                                          },
                                          expression: "tempFollowup.subject",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "is-required",
                                      attrs: { label: "类型:", prop: "type" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "filter-item",
                                          staticStyle: { width: "100%" },
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.tempFollowup.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempFollowup,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "tempFollowup.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.typeOptionsArr,
                                          function (e, i) {
                                            return _c("el-option", {
                                              key: i,
                                              attrs: { label: e, value: i },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "下次提醒日期:" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          placeholder: "选择日期",
                                          "value-format": "timestamp",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.tempFollowup.next_time,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tempFollowup,
                                              "next_time",
                                              $$v
                                            )
                                          },
                                          expression: "tempFollowup.next_time",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "30px" },
                                      attrs: { label: "附件:" },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "avatar-uploader",
                                          attrs: {
                                            "http-request":
                                              _vm.uploadSectionFile,
                                            "show-file-list": true,
                                            action: "action",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "400px",
                                overflow: "auto",
                              },
                            },
                            [
                              _c("div", [_c("h4", [_vm._v("跟进记录")])]),
                              _vm._v(" "),
                              _vm.follows_up.length != 0
                                ? _c(
                                    "el-timeline",
                                    _vm._l(
                                      _vm.follows_up,
                                      function (activity, index) {
                                        return _c(
                                          "el-timeline-item",
                                          {
                                            key: index,
                                            attrs: { placement: "top" },
                                          },
                                          [
                                            _c(
                                              "el-card",
                                              {
                                                attrs: {
                                                  "body-style": {
                                                    padding: "15px",
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "g6 lh18" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("类型："),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(activity.type)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "g6 lh18" },
                                                  [
                                                    _c("span", [
                                                      _vm._v("主题 ："),
                                                    ]),
                                                    _vm._v(
                                                      _vm._s(activity.subject)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                activity.file_info !== ""
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "g6 lh18 blueFont poi",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        附件 ："
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "blueFont",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                activity
                                                                  .file_info
                                                                  .file_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                activity.next_time
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "g6 lh18",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "下次提醒时间 ：" +
                                                            _vm._s(
                                                              activity.next_time
                                                            )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _c(
                                    "h3",
                                    { staticStyle: { "text-align": "center" } },
                                    [_vm._v("暂无跟进记录")]
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.followUpDialog = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    取消\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addFollowUp()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    确定\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("staff-select", {
              ref: "staffSelect",
              attrs: {
                "disable-arr": _vm.staffDisableArr,
                radio: _vm.radio,
                condition: [0],
                visible: _vm.staffSelectVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.staffSelectVisible = $event
                },
                seasbookstaff: _vm.seasbookstaff,
              },
              model: {
                value: _vm.staffResult,
                callback: function ($$v) {
                  _vm.staffResult = $$v
                },
                expression: "staffResult",
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }